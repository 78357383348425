form.update {
    width: 50%;
    margin: auto;
}

form.update h3 {
    margin-bottom: 10px;
}

form.update p {
    margin-bottom: 20px;
}

form.update p.change {
    margin-bottom: 0;
}

form.update input,
form.update select,
form.update button {
    display: inline-block;
    border-radius: 20px;
    margin: 10px auto;
}

form.update input, form.update select {
    width: 60%;
    background-color: #16161A;
    color: #FFFFFE;
    border: 2px solid #2CB67D;
    padding: 15px;
}

form.update button,
form.update input[type="submit"] {
    width: 20%;
    padding: 10px;
    color: #FFFFFE;
    cursor: pointer;
}

form.update button {
    border: 2px solid #2CB67D;
    background-color: #16161A;
}

form.update input[type="submit"] {
    background-color: #2CB67D;
    border: none;
    margin-left: 10px;
}

form.update button.disabled,  form.update button.disabled:hover {
    background-color: grey;
    border: none;
}

form.update button:hover {
    background-color: #2CB67D;
}

/* Media queries for responsive */
@media screen and (max-width: 850px) {
    form.update {
        width: 80%;
    }
}

@media screen and (max-width: 550px) {
    form.update {
        width: 90%;
    }

    form.update input,
    form.update select {
        width: 70%;
    }

    form.update button,
    form.update input[type="submit"] {
        width: 30%;
    }
}

@media screen and (max-width: 550px) {
    form.update {
        width: 90%;
    }

    form.update input,
    form.update select {
        width: 90%;
    }

    form.update button,
    form.update input[type="submit"] {
        width: 40%;
    }
}