.admin-dashboard {
    width: 65%;
    margin: 3% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2% 7%;
}

.admin-dashboard h1 {
    font-size: 2rem;
    padding: 1% 3%;
    margin: 2px 10%;
}

.admin-dashboard p {
    font-size: 1.2rem;
    padding: 1% 3%;
    width: 50%;
    text-align: center;
    margin: 0.5% auto;
    margin-bottom: 3%;
}

.admin-dashboard a {
    text-decoration: none;
    border-radius: 30px;
    width: 60%;
    display: inline-block;
    text-align: center;
    padding: 3% 5%;
    margin: 1.5%;
    background-color: #787878;
    color: #FFFFFE;
}


/* Media queries for responsive */
@media screen and (min-width: 701px) and (max-width: 900px) {
    .admin-dashboard {
        width: 80%;
    }
}

@media screen and (max-width: 700px) {
    .admin-dashboard {
        width: 100%;
        padding: 1% 3%;
        margin-top: 10%;
    }

    .admin-dashboard h1 {
        font-size: 1.5rem;
    }

    .admin-dashboard p {
        font-size: 1rem;
        width: 100%;
    }

    .admin-dashboard h1, .admin-dashboard p {
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 400px) {
    .admin-dashboard a {
        width: 80%;
        margin-bottom: 10px;
    }
}